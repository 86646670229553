import {
  actions as apiClientActions,
  apiCallErrorChannel,
  apiCallRequestedChannel,
  apiCallSuccessChannel,
} from '@spq/redux-api-client';
import { mapValues } from 'lodash';
import { go, LOCATION_CHANGE, push } from 'redux-first-history';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import smartlookClient from 'smartlook-client';

import * as pageActions from '../actions/page';
import * as userActions from '../actions/user';
import auth2Enum from '../enums/auth2Enum';
import statusEnum from '../enums/statusEnum';
import {
  deleteSearchParam,
  determineStepsToDesiredLocation,
  getSearchParam,
  setSearchParams,
} from '../utils/historyUtils';

import * as selectors from './selectors';

import ecomService, {
  apiServices,
  auth2Service,
  oauthService,
  paymentService,
} from '../services/api';
import * as Sentry from '../services/sentry';

export function* clearSignInHistory() {
  const pathname = yield select(selectors.getAfterSignInPath);
  const { currentIndex, locations } = yield select(selectors.getHistory);

  const returningSteps = determineStepsToDesiredLocation(currentIndex, locations, pathname);

  yield put(go(returningSteps));
  yield take(LOCATION_CHANGE);
}

function* handlePhoneVerifySuccess() {
  yield put(userActions.signedIn());
}

function* handleAuthorizedUser({ endpointName }) {
  const newAccountAuth = [`${auth2Service.signUp}`, `${auth2Service.facebookLogin}`];

  const token = yield select(selectors.getUserToken);
  mapValues(apiServices, (val) => {
    val.setToken(token);
  });

  yield put(auth2Service.profile.requestActionCreator());
  yield put(ecomService.orders.requestActionCreator({ page: 1, clearPrevious: true }));
  yield put(ecomService.favoriteMenuItems.requestActionCreator({ page: 1, clearPrevious: true }));
  yield put(paymentService.paymentMethod.requestActionCreator());

  if (newAccountAuth.includes(endpointName)) {
    yield call(clearSignInHistory);
    if (newAccountAuth.includes([`${auth2Service.facebookLogin}`])) {
      yield put(push({ pathname: '/signIn/marketingOptIn' }));
    } else {
      yield put(push({ pathname: '/signIn/phone' }));
    }
  }
}

function* handleGuestUser() {
  // get payment method without token
  yield put(paymentService.paymentMethod.requestActionCreator());
}

function* handleGuestSession() {
  const guestToken = yield select(selectors.getGuestToken);
  ecomService.setGuestToken(guestToken);
  yield put(
    ecomService.orders.requestActionCreator({
      page: 1,
      clearPrevious: true,
    }),
  );
}

function* handleCloseMarketingOptIn() {
  yield put(push({ pathname: '/signIn/phone' }));
}

function* handleSignInProcessCompleted() {
  const currentLocation = yield select(selectors.getCurrentLocation);

  const isGuestLogin = yield select(selectors.getGuestLogin);
  const loggedIn = !!(yield select(selectors.getUserToken));
  if (loggedIn || !isGuestLogin) {
    if (['/signIn/phone'].includes(currentLocation.pathname) === false) {
      const { pathname, search } = yield select(selectors.getAfterSignIn);

      if (!search || !getSearchParam(search, 'placeOrder')) {
        yield call(clearSignInHistory);
        yield put(userActions.afterSignIn({ pathname: '/locations' }));
        yield put(push({ pathname, search }));
      }
    }
  } else if (isGuestLogin) {
    yield handleGuestUser();
    const { pathname, search } = yield select(selectors.getAfterSignIn);

    if (!search || !getSearchParam(search, 'placeOrder')) {
      yield call(clearSignInHistory);
      yield put(userActions.afterSignIn({ pathname: '/locations' }));
      yield put(push({ pathname, search }));
    }
  }
}

function* handleSignInProcessStep() {
  const user = yield select(selectors.getUser);
  const apiStatus = yield select(selectors.getApiStatus);

  if (user.signedIn === false) {
    const requiredStepsStatus = [apiStatus.paymentMethod, user.status.profile, apiStatus.orders];
    if (requiredStepsStatus.every((status) => status === statusEnum.SUCCESS)) {
      yield put(userActions.signedIn());
      yield put(ecomService.customerAddresses.requestActionCreator());
    }
  }
}

function tagSmartlookUser({ uuid, email }) {
  const uniqueId = parseInt(uuid.replace(/-/g, '').slice(0, 12), 16);

  smartlookClient.identify(uniqueId, {
    uuid,
    email,
  });
}

function tagSentryUser({ uuid, email }) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: uuid, username: email, email });
  });
}

function tagUser(action) {
  const { response } = action.response;
  const { email } = response;
  const uuid = response.userUuid;

  tagSmartlookUser({ uuid, email });
  tagSentryUser({ uuid, email });
}

function* handleFetchPaymentMethodsSuccess() {
  yield handleSignInProcessStep();
}

function* handleFetchProfileSuccess(action) {
  yield handleSignInProcessStep();
  tagUser(action);
}

function* handleFetchOrdersSuccess() {
  yield handleSignInProcessStep();
}

function* signInDispatch() {
  const email = yield select(selectors.getRegistrationEmail);
  const body = {
    email,
  };

  const result = yield oauthService.checkUserExists.call({ body });

  yield put(
    apiClientActions.apiCallResult({
      apiName: oauthService.apiName,
      apiReference: oauthService.apiReference,
      endpointName: `${oauthService.checkUserExists}`,
      result,
    }),
  );

  const { response } = result;
  if (response) {
    yield put(pageActions.setIsGuestLoginFlow(false));
    yield put(userActions.guestLogout());
    if (response.exists === false) {
      yield put(push({ pathname: '/signIn/register' }));
    } else if (response.isSocialLogin === false) {
      yield put(push({ pathname: '/signIn/login' }));
    } else {
      yield put(userActions.changeLoginMethod('FACEBOOK'));
    }
  }
}

function* handleFacebookLoginError({ error, requestOptions }) {
  const { accessToken } = requestOptions;
  if (error.code === auth2Enum.EMAIL_SIGNUP) {
    yield put(userActions.changeLoginMethod('PASSWORD'));
  }

  if (error.code === auth2Enum.FACEBOOK_NO_EMAIL) {
    yield put(
      push(
        {
          pathname: '/signIn/facebookRequestEmail',
        },
        {
          accessToken,
        },
      ),
    );
  }
}

function* autologin() {
  const { pathname, search } = yield select(selectors.getCurrentLocation);

  yield put(
    userActions.beforeSignIn({
      pathname,
      search: deleteSearchParam(search, 'autologin'),
    }),
  );
  yield put(
    userActions.afterSignIn({
      pathname,
      search: deleteSearchParam(search, 'autologin'),
    }),
  );
}

function* handleRemovePaymentMethodSuccess() {
  yield put(paymentService.paymentMethod.requestActionCreator());
}

function* handleSetDefaultPaymentMethodsSuccess() {
  yield put(paymentService.paymentMethod.requestActionCreator());
}

function* handleCreatePhoneSuccess() {
  yield put(push({ pathname: '/signIn/verify' }));
}

function* changeLoginMethod(action) {
  const { search } = yield select(selectors.getCurrentLocation);
  yield put(
    push({
      pathname: '/signIn',
      search: setSearchParams(search, { method: action.method }),
    }),
  );
}

function* checkApiErrorForInvalidToken(action) {
  const { error } = action;
  const token = yield select(selectors.getUserToken);

  if (token && error.message === 'Invalid token.') {
    yield put(userActions.signOut());
    yield put(userActions.showInvalidTokenModal());
  }
}

function* checkApiErrorForExpiredGuestSession(action) {
  const { error } = action;
  const hasGuestSession = yield select(selectors.getHasGuestSession);

  if (
    hasGuestSession &&
    (error.message === 'Authentication credentials were not provided.' ||
      error.message === 'Invalid guest token')
  ) {
    yield put(userActions.guestLogout());
  }
}

function* handlePusherConnected() {
  const userToken = yield select(selectors.getUserToken);
  const hasGuestSession = yield select(selectors.getHasGuestSession);
  const guestToken = yield select(selectors.getGuestToken);

  if (userToken) {
    yield put(ecomService.orders.requestActionCreator({ page: 1, clearPrevious: true }));
  } else if (hasGuestSession) {
    yield put(userActions.guestLogin());
    ecomService.setGuestToken(guestToken);
    yield put(
      ecomService.orders.requestActionCreator({
        page: 1,
        clearPrevious: true,
      }),
    );
  }
}

function* handleRehydrate() {
  const userToken = yield select(selectors.getUserToken);
  const isVersionUpdated = yield select(selectors.getIsVersionUpdated);
  mapValues(apiServices, (val) => {
    val.setToken(userToken);
  });
  if (userToken) {
    yield put(auth2Service.profile.requestActionCreator());
    yield put(ecomService.customerAddresses.requestActionCreator());
    yield put(paymentService.paymentMethod.requestActionCreator());

    if (isVersionUpdated) {
      // Perform any necessary actions after version update for logged in user
      yield put(
        ecomService.favoriteMenuItems.requestActionCreator({ page: 1, clearPrevious: true }),
      );
    }
  }
  // Set update as false after rehydration
  yield put(userActions.clearVersionUpdateFlag());
}

function handleSignOut() {
  mapValues(apiServices, (val) => {
    val.setToken();
  });
  ecomService.setGuestToken();
}

function* watchUser() {
  yield takeEvery(userActions.SIGN_IN_DISPATCH, signInDispatch);
  yield takeEvery(apiCallRequestedChannel(`${auth2Service.autoLogin}`), autologin);
  yield takeEvery(userActions.SIGNED_IN, handleSignInProcessCompleted);
  yield takeEvery(userActions.CLOSE_MARKETING_OPT_IN, handleCloseMarketingOptIn);
  yield takeEvery(userActions.SET_GUEST_PHONE, handleSignInProcessCompleted);
}

function* watchApiCall() {
  yield takeEvery(apiCallSuccessChannel(`${auth2Service.profile}`), handleFetchProfileSuccess);
  yield takeEvery(apiCallSuccessChannel(`${oauthService.createPhone}`), handleCreatePhoneSuccess);
  yield takeEvery(apiCallSuccessChannel(`${oauthService.verifyPin}`), handlePhoneVerifySuccess);
  yield takeEvery(
    apiCallSuccessChannel(`${paymentService.paymentMethod}`),
    handleFetchPaymentMethodsSuccess,
  );
  yield takeEvery(
    apiCallSuccessChannel(`${paymentService.setDefaultPaymentMethod}`),
    handleSetDefaultPaymentMethodsSuccess,
  );
  yield takeEvery(
    apiCallSuccessChannel(`${paymentService.removePaymentMethod}`),
    handleRemovePaymentMethodSuccess,
  );
  yield takeEvery(apiCallSuccessChannel(`${ecomService.orders}`), handleFetchOrdersSuccess);
  yield takeEvery(apiCallSuccessChannel(`${auth2Service.facebookLogin}`), handleAuthorizedUser);
  yield takeEvery(apiCallSuccessChannel(`${auth2Service.login}`), handleAuthorizedUser);
  yield takeEvery(apiCallSuccessChannel(`${auth2Service.autoLogin}`), handleAuthorizedUser);
  yield takeEvery(apiCallSuccessChannel(`${auth2Service.signUp}`), handleAuthorizedUser);
}

function* watchGuestSession() {
  yield takeEvery(apiClientActions.API_CALL_ERROR, checkApiErrorForExpiredGuestSession);
  yield takeEvery(userActions.START_HAS_GUEST_SESSION, handleGuestSession);
}

function* watchFacebook() {
  yield takeEvery(apiCallErrorChannel(`${auth2Service.facebookLogin}`), handleFacebookLoginError);
}

function* watchChangeLoginMethod() {
  yield takeEvery(userActions.CHANGE_LOGIN_METHOD, changeLoginMethod);
}

function* watchInvalidToken() {
  yield takeEvery(apiClientActions.API_CALL_ERROR, checkApiErrorForInvalidToken);
}

function* watchPusher() {
  yield takeEvery('PUSHER_CONNECTED', handlePusherConnected);
}

function* watchRehydrate() {
  yield takeEvery(REHYDRATE, handleRehydrate);
}

function* watchSignOut() {
  yield takeEvery(userActions.SIGN_OUT, handleSignOut);
  yield takeEvery(userActions.GUEST_LOGOUT, handleSignOut);
}

export default function* userSaga() {
  yield all([
    watchUser(),
    watchApiCall(),
    watchFacebook(),
    watchChangeLoginMethod(),
    watchInvalidToken(),
    watchGuestSession(),
    watchPusher(),
    watchRehydrate(),
    watchSignOut(),
  ]);
}
