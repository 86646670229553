import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';

import loadingSalad from '../LoadingScreen/loading-salad.gif';
import Modal from '../Modal';

import './UserSpinner.css';

function UserSpinner(props) {
  const hasGuestSession = useSelector(selectors.getHasGuestSession);
  const isGuestLogin = useSelector(selectors.getGuestLogin);

  if (hasGuestSession || isGuestLogin) {
    return null;
  }

  const { status } = props;

  const isAnythingRequested = Object.values(status).some(
    (subStatus) => subStatus === statusEnum.REQUESTED,
  );

  let message = '';
  if (status.signUp === statusEnum.REQUESTED) {
    message = 'Signing up..';
  } else if (status.login === statusEnum.REQUESTED) {
    message = 'Signing in...';
  } else if (status.facebookLogin === statusEnum.REQUESTED) {
    message = 'Signing in...';
  } else if (status.forgetPassword === statusEnum.REQUESTED) {
    message = 'Sending reset link...';
  } else if (status.paymentMethods === statusEnum.REQUESTED) {
    message = 'Authenticating...';
  } else if (status.orders === statusEnum.REQUESTED) {
    message = 'Finishing...';
  } else if (status.updateProfile === statusEnum.REQUESTED) {
    message = 'Updating profile...';
  } else if (status.changePassword === statusEnum.REQUESTED) {
    message = 'Updating password...';
  } else if (status.cancelOrder === statusEnum.REQUESTED) {
    message = 'Cancelling order...';
  }

  return (
    <Modal className="UserSpinnerModal modal-small" show={isAnythingRequested}>
      <Modal.Body className="kale">
        {message && (
          <Typography variant="calafia2" color="primary">
            {message}
          </Typography>
        )}
        <img className="SaladAnimation" alt="" src={loadingSalad} />
      </Modal.Body>
    </Modal>
  );
}

UserSpinner.propTypes = {
  status: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default UserSpinner;
