import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as historyUtils from '../../utils/historyUtils';

import historyShape from '../../shapes/historyShape';

import App from '../../components/App';

import * as settings from '../../settings';

if (Object.keys(settings).includes('GTM_ID')) {
  // eslint-disable-next-line global-require
  const { GTM_ID } = require('../../settings');
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

if (Object.keys(settings).includes('GA_ID')) {
  // eslint-disable-next-line global-require
  const { GA_ID } = require('../../settings');
  ReactGA.initialize(GA_ID);
}

const googleSiteVerificationContent = (() => {
  if (Object.keys(settings).includes('GOOGLE_SITE_VERIFICATION_CONTENT')) {
    // eslint-disable-next-line global-require
    const { GOOGLE_SITE_VERIFICATION_CONTENT } = require('../../settings');
    return GOOGLE_SITE_VERIFICATION_CONTENT;
  }
  return null;
})();

class AppContainer extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    history: historyShape.isRequired,
  };

  get currentLocation() {
    const { history } = this.props;

    return history.locations[history.currentIndex];
  }

  get currentFlow() {
    return historyUtils.findRoutesFlow(this.currentLocation?.flow);
  }

  get currentRoute() {
    return historyUtils.findLocationRoute(this.currentLocation, this.currentFlow.routes);
  }

  get className() {
    const { loggedIn } = this.props;

    return classnames(this.currentRoute?.className, { loggedIn });
  }

  render() {
    return (
      <HelmetProvider>
        <App className={this.className}>
          {googleSiteVerificationContent && (
            <Helmet>
              <meta name="google-site-verification" content={googleSiteVerificationContent} />
            </Helmet>
          )}
          <Outlet />
        </App>
      </HelmetProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  loggedIn: !!state.user.token,
});

export default connect(mapStateToProps, undefined)(AppContainer);
