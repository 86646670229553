import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as orderActions from '../../actions/order';
import * as userActions from '../../actions/user';
import diningChoiceEnum from '../../enums/diningChoiceEnum';
import { getScheduleTimeRangeFromReadyTime } from '../../utils/gglocationUtils';
import { getSearchParam } from '../../utils/historyUtils';
import * as orderUtils from '../../utils/orderUtils';

import favoriteMenuItemShape from '../../shapes/favoriteMenuItemShape';
import gglocationShape from '../../shapes/gglocationShape';
import menuItemIngredientServingsShape from '../../shapes/menuItemIngredientServingsShape';
import menuItemShape from '../../shapes/menuItemShape';
import routerShape, { routerLocationShape } from '../../shapes/routerShape';
import userOrderShape from '../../shapes/userOrderShape';

import Order from '../../components/Order';
import withRouter from '../WithRouter';

import ecomService from '../../services/api';

class OrderContainer extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    hasGuestSession: PropTypes.bool.isRequired,
    userOrders: PropTypes.objectOf(userOrderShape).isRequired,
    gglocations: PropTypes.objectOf(gglocationShape).isRequired,
    favoriteMenuItems: PropTypes.objectOf(favoriteMenuItemShape).isRequired,
    menuItems: PropTypes.objectOf(menuItemShape).isRequired,
    menuItemIngredientServings: menuItemIngredientServingsShape.isRequired,

    replaceOrderErrorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,

    addFavoriteMenuItem: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    afterSignIn: PropTypes.func.isRequired,
    replaceOrderDispatch: PropTypes.func.isRequired,
    isGuestLogin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    const { loggedIn, hasGuestSession, userOrders, router, afterSignIn, location, isGuestLogin } =
      props;
    const { orderId } = router.params;

    super(props);

    this.state = {
      favoriteOrderItem: null,
      showPickFavoriteModal: false,
      showOrderCancelModal: false,
      showOrderCancelledModal: false,
      hasGuestSession,
    };

    if (userOrders[orderId] === undefined) {
      if (loggedIn === false && !hasGuestSession && !isGuestLogin) {
        afterSignIn({
          pathname: location.pathname,
          search: location.search,
        });
        router.push('/signIn');
        return;
      }
      if (loggedIn) {
        router.push('/account/orders');
      } else {
        router.push({ pathname: '/' });
      }
    }
  }

  componentDidMount() {
    this.initializePickFavoriteModal();
  }

  componentDidUpdate(prevProps) {
    const { showPickFavoriteModal } = this.state;

    if (showPickFavoriteModal && this.isEveryMenuItemFavorited) {
      this.handlePickFavoriteCancel();
    }

    const { userOrders, router } = prevProps;
    const { orderId } = router.params;
    const prevUserOrder = userOrders[orderId];
    const currentUserOrder = this.userOrder;

    if (prevUserOrder && currentUserOrder) {
      this.handleOrderStatusUpdated(prevUserOrder.orderStatusId, currentUserOrder.orderStatusId);
    }
  }

  get orderId() {
    const { router } = this.props;

    return router.params?.orderId;
  }

  get userOrder() {
    const { userOrders } = this.props;

    return userOrders[this.orderId] || null;
  }

  get userOrderItems() {
    return this.userOrder.ordermenu;
  }

  get isEveryMenuItemFavorited() {
    const { favoriteMenuItems, menuItems, menuItemIngredientServings } = this.props;

    return this.userOrderItems.every((orderItem) =>
      orderUtils.isOrderItemFavorite(orderItem, {
        favoriteMenuItems,
        menuItems,
        menuItemIngredientServings,
      }),
    );
  }

  get gglocation() {
    const { gglocations } = this.props;

    return gglocations[`${this.userOrder.gglocationType}_${this.userOrder.gglocationId}`];
  }

  get totalPrice() {
    return this.userOrder.total;
  }

  get showPickFavoriteModal() {
    const { showPickFavoriteModal, favoriteOrderItem } = this.state;

    /* Hide pick favorite modal for the time of naming an order item */
    if (favoriteOrderItem) return false;

    return showPickFavoriteModal;
  }

  get isDeliveryOrder() {
    const { userOrder } = this;

    if (userOrder.diningChoice !== diningChoiceEnum.DELIVERY) {
      return false;
    }

    return true;
  }

  get deliveryAddressLabel() {
    const { userOrder, isDeliveryOrder } = this;

    if (!isDeliveryOrder || !userOrder.deliveryAddress) {
      return null;
    }

    return userOrder.deliveryAddress.label;
  }

  get readyTime() {
    const { isDeliveryOrder, userOrder } = this;

    if (!isDeliveryOrder) {
      return userOrder.pickupTime;
    }

    return userOrder.estimatedDelivery;
  }

  get readyTimeRange() {
    const { isDeliveryOrder } = this;

    if (!isDeliveryOrder) {
      return null;
    }

    const { userOrder, readyTime, gglocation } = this;
    const { diningChoice } = userOrder;

    return getScheduleTimeRangeFromReadyTime({
      scheduler: gglocation,
      readyTime,
      diningChoice,
    });
  }

  get openingHours() {
    const { gglocation } = this;
    const { diningChoice } = this.userOrder;

    // Retrieve store opening hours for pickup order only
    if (diningChoice !== diningChoiceEnum.TAKE_AWAY || !gglocation || !gglocation.openingHours) {
      return null;
    }

    const openingHoursOnDate = gglocation.selectedDateOpeningHours({ selectedDate: moment() });

    if (openingHoursOnDate) {
      const { openingDatetime, closingDatetime } = openingHoursOnDate;

      const openingHoursString = `${moment(openingDatetime).format('ddd h:mma')} - ${moment(
        closingDatetime,
      ).format('ddd h:mma')}`;

      return openingHoursString;
    }

    return null;
  }

  get lastOrderTime() {
    const { gglocation } = this;
    const { diningChoice } = this.userOrder;

    if (diningChoice !== diningChoiceEnum.TAKE_AWAY || !gglocation || !gglocation.openingHours)
      return null;

    const openingHoursOnDate = gglocation.selectedDateOpeningHours({ selectedDate: moment() });

    if (openingHoursOnDate) {
      const { closingDatetime } = openingHoursOnDate;

      return moment(closingDatetime)
        .subtract(gglocation.getProcessingTime({ diningChoice }), 'minutes')
        .format('h:mma');
    }
    return null;
  }

  get showOrderReplace() {
    const { replaceOrderErrorIds } = this.props;
    if (replaceOrderErrorIds.indexOf(this.orderId) > -1) {
      return false;
    }
    return true;
  }

  handleOrderStatusUpdated = (previousOrderStatus, nextOrderStatus) => {
    if (
      orderUtils.isOrderStatusPending(previousOrderStatus) &&
      orderUtils.isOrderStatusStoreCancelled(nextOrderStatus)
    ) {
      this.setState({ showOrderCancelledModal: true });
    }
  };

  handleFavoriteRequest = (favoriteOrderItem) => {
    this.setState({ favoriteOrderItem });
  };

  handleNameFavoriteCancel = () => {
    this.setState({ favoriteOrderItem: null });
  };

  handleNameFavoriteSave = (orderItem, name) => {
    const { addFavoriteMenuItem } = this.props;

    this.setState({ favoriteOrderItem: null });
    addFavoriteMenuItem({ orderItem, name });
  };

  handlePickFavoriteCancel = () => {
    this.setState({ showPickFavoriteModal: false });
  };

  handleOrderCancelConfirm = () => {
    const { cancelOrder } = this.props;

    cancelOrder({ orderId: this.userOrder.id, cancellationRemark: '' });
    this.setState({ showOrderCancelModal: false });
  };

  handleOrderCancelRequest = () => {
    this.setState({ showOrderCancelModal: true });
  };

  handleOrderCancelModalHide = () => {
    this.setState({ showOrderCancelModal: false });
  };

  handleOrderCancelledModalHide = () => {
    this.setState({ showOrderCancelledModal: false });
  };

  handleOrderReplace = () => {
    const { replaceOrderDispatch } = this.props;

    replaceOrderDispatch({ userOrderId: this.userOrder.id });
  };

  initializePickFavoriteModal() {
    const { location, hasGuestSession } = this.props;
    const { search } = location;
    const redirected = getSearchParam(search, 'redirected');

    /**
     * Check if user order exists first, because /orders api is
     * currently being fetched independently against ApiProvider and
     * tends to be slower, and if the first entry point of current session
     * is order page, then accessing order from here will broke the app
     * as it is being called from componentOnMount.
     */
    if (this.userOrder && redirected) {
      if (this.isEveryMenuItemFavorited === false && !hasGuestSession) {
        this.setState({ showPickFavoriteModal: true });
      }
    }
  }

  render() {
    if (this.userOrder === null) return null;

    const { favoriteOrderItem, showOrderCancelModal, showOrderCancelledModal, hasGuestSession } =
      this.state;
    const { userOrder } = this;

    return (
      <Order
        orderId={this.orderId}
        gglocation={this.gglocation}
        orderItems={userOrder.ordermenu}
        totalPrice={this.totalPrice}
        status={userOrder.orderStatusId}
        pickupCode={userOrder.orderNumber}
        created={userOrder.created}
        readyTime={this.readyTime}
        readyTimeRange={this.readyTimeRange}
        cancellationRemark={userOrder.cancellationRemark}
        favoriteOrderItem={favoriteOrderItem}
        deliveryAddressLabel={this.deliveryAddressLabel}
        isDeliveryOrder={this.isDeliveryOrder}
        deliveryInfo={userOrder.deliveryInfo}
        openingHours={this.openingHours}
        lastOrderTime={this.lastOrderTime}
        showPickFavoriteModal={this.showPickFavoriteModal}
        showOrderCancelModal={showOrderCancelModal}
        showOrderCancelledModal={showOrderCancelledModal}
        showOrderReplace={this.showOrderReplace}
        onFavoriteRequest={this.handleFavoriteRequest}
        onNameFavoriteCancel={this.handleNameFavoriteCancel}
        onNameFavoriteSave={this.handleNameFavoriteSave}
        onPickFavoriteCancel={this.handlePickFavoriteCancel}
        onOrderCancelModalHide={this.handleOrderCancelModalHide}
        onOrderCancelledModalHide={this.handleOrderCancelledModalHide}
        onOrderCancelConfirm={this.handleOrderCancelConfirm}
        onOrderCancelRequest={this.handleOrderCancelRequest}
        onOrderReplace={this.handleOrderReplace}
        hasGuestSession={hasGuestSession}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.user.token,
  hasGuestSession: state.user.hasGuestSession,
  location: state.router.location,
  userOrders: state.api.userOrders,
  gglocations: state.api.gglocations,
  favoriteMenuItems: state.api.favoriteMenuItems,
  menuItems: state.api.menuItems,
  menuItemIngredientServings: state.api.menuItemIngredientServings,
  replaceOrderErrorIds: state.order.replaceOrderErrorIds,
  isGuestLogin: state.user.guestLogin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addFavoriteMenuItem: ecomService.addFavoriteMenuItem.requestActionCreator,
      cancelOrder: ecomService.cancelOrder.requestActionCreator,
      afterSignIn: userActions.afterSignIn,
      replaceOrderDispatch: orderActions.replaceOrderDispatch,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderContainer));
